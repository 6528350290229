var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Entry',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" BootstrapVue form component and helper components that optionally support inline form styles and validation states. Pair them up with other BootstrapVue form control components for an easy customized, and responsive, layout with a consistent look and feel. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('introduction')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('inline')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('form-text')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('feedback')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }